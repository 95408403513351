import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CompanySelector from "../components/CompanySelector";
import QuizzContent from "../components/QuizzContent";
import { loadAllQuestionsForQuizz } from "../utils/quizz/Quizz.firebase";
import { Company, Question } from "../utils/quizz/Quizz.types";
import Logo from "../assets/devduck_logo.png";
import { ReactComponent as HeaderIcon } from "../assets/Pokal.svg";
import { ReactComponent as Background } from "../assets/background.svg";

const QuizzPage: React.FC<{}> = () => {
  const params = useParams<{ uid: string }>();
  const [loadedQuestions, setLoadedQuestions] = useState<Question[]>([]);
  const [selectedCompany, setCompany] = useState<Company>(Company.UNDECIDED);

  // load the question corresponding to the quizz
  useEffect(() => {
    loadAllQuestionsForQuizz("c77ef2c9-50c4-4b1f-9edb-869cb7794643").then(
      setLoadedQuestions
    );
  }, [params]);

  // decide which entries to display
  const contentToRender = useMemo<JSX.Element>(() => {
    if (selectedCompany === Company.UNDECIDED)
      return <CompanySelector onSelect={setCompany} />;
    return (
      <QuizzContent
        selectedCompany={selectedCompany}
        questions={loadedQuestions}
      />
    );
  }, [selectedCompany, loadedQuestions]);

  return (
    <div className="workshop__wrapper">
      <div className="workshop__header">
        <Background className="workshop__header__image" />
        <h1 className="workshop__header__title">DevDuck Gewinnspiel</h1>
        <HeaderIcon className="workshop__header__icon" />
      </div>
      <div className="workshop__content">{contentToRender}</div>
      <div className="workshop__footer">
        <div className="workshop__footer__logo">
          <img src={Logo} alt="DevDuck Logo" title="DevDuck Logo" />
        </div>
        <div className="workshop__footer__links">
          <a
            href="https://www.devduck.de/datenschutz-messe/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Datenschutz
          </a>
          <a
            href="https://www.devduck.de/impressum/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Impressum
          </a>
        </div>
      </div>
    </div>
  );
};
export default QuizzPage;
