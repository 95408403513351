import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import DevDuckLogo from "../assets/devduck_logo.png";
import DuckeneersLogo from "../assets/duckeneers_logo.png";
import QrCode from "../assets/qr_code.png";
import Illu from "../assets/web_illustration.png";

const LandingPage: React.FC<{}> = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-page__wrapper">
      <div className="landing-page__header">
        <img
          src={DevDuckLogo}
          alt="DevDuck Logo"
          className="landing-page__header__image"
        />
        <img
          src={DuckeneersLogo}
          alt="Duckeneers Logo"
          className="landing-page__header__image"
        />
      </div>
      <h1>
        Du denkst du weißt alles über die
        <br />
        aktuellsten Technologien?
      </h1>
      <div className="landing-page__content__wrapper">
        <div className="landing-page__content__qr__wrapper">
          <Arrow className="landing-page__content__qr__arrow" />
          <div className="landing-page__content__qr__text">
            Zeig her was du kannst & gewinne eine Drohne!
          </div>
          <img
            src={QrCode}
            alt="QR Code"
            onClick={() =>
              navigate("/quizz/c77ef2c9-50c4-4b1f-9edb-869cb7794643")
            }
            className="landing-page__content__qr__code"
          />
        </div>
        <img
          src={Illu}
          alt="Web Illustration"
          className="landing-page__content__illu"
        />
      </div>
    </div>
  );
};

export default LandingPage;
