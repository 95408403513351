import { v4 as uuid4 } from "uuid";

export interface Question {
  id: string;
  quizzId: string;
  title: string;
  answers: Answer[];
  company: Company;
}

export interface Answer {
  id: string;
  isCorrect: boolean;
  value: string;
  icon?: string;
}

export enum Company {
  DEVDUCK = "DEVDUCK",
  DUCKENEERS = "DUCKENEERS",
  UNDECIDED = "UNDECIDED",
}

export const DuckeneersQuizzEntries: Question[] = [
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "PyTorch",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "Django",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "TensorFlow",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Jax",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ist kein Machine Learning Framework?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "Cable Network News",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "Convolutional Neural Network",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Wofür steht eigentlich CNN?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "Github",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Twitter",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Firebase",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "Huggingface",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ist eine populäre Deeplearning Plattform?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "Avengers",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "Transformer",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Venom",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Thor",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ist eine Deep Learning Architektur?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "DALL-E",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Codex",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "VGGNet",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Gato",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ist kein Modell mit einer Transformer Achitektur?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "YOLO",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "MobileNet",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "DETR",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "GTP-3",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ist kein Object Detection Model?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: true,
        value: "100% wahr :)",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Kann ich mir nicht vorstellen",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Enten sind echte Alles-Könner!",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "Monte Carlo Tree Search",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "Backpropagation",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "K Nearest Neighbors",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Reinforcement Learning",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Wie heißt das typische Lernverfahren für Neuronale Netze?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: true,
        value: "Python, C++, Java",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "C#, JavaScript, PHP",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "Neuronale Netze sind nur in Python möglich",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "In allen",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title:
      "In welchen Programmiersprachen kann man Neuronale Netze programmieren?",
  },

  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "Im Einzelhandel",
      },

      {
        id: uuid4(),
        isCorrect: true,
        value: "In der Logistik",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "In der Automobilbranche",
      },

      {
        id: uuid4(),
        isCorrect: false,
        value: "In Luft und Raumfahrt",
      },
    ],
    company: Company.DUCKENEERS,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "In welchen Branchen ist Duckeneers tätig?",
  },
];

export const DevDuckQuizzEntries: Question[] = [
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: true,
        value: "Progressive Web App",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Physical Web API",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Playable Web Audio",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Private Web Access",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Wofür steht eigentlich PWA?",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: true,
        value: "Richtig",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Falsch",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "TypeScript ist ein Superset von JavaScript",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: true,
        value: "Absolut! 🦆",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Auf keinen Fall 🐥",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Enten sind echte Alles-Könner!",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: true,
        value: "Thymeleaf",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "ReactJS",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Jest",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Sass",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was davon ist KEIN Frontend-Framework?",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: true,
        value: "IndexedDB",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Session Storage",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Cookie",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "React State",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title:
      "In welcher Umgebung werden Daten standardmäßig am längsten persistiert?",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "JAM",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "LAMP",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "MERN",
      },
      {
        id: uuid4(),
        isCorrect: true,
        value: "COUCH",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ist kein Dev-Stack?",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "Header, Payload, Params",
      },
      {
        id: uuid4(),
        isCorrect: true,
        value: "Header, Payload, Signature",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Header, Body, Payload",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "Header, Content, Footer",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Aus welchen Bestandteilen setzt sich ein JWT zusammen?",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "GET",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "PATCH",
      },
      {
        id: uuid4(),
        isCorrect: true,
        value: "REFRESH",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "DELETE",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ist KEINE HTTP Methode?",
  },
  {
    id: uuid4(),
    answers: [
      {
        id: uuid4(),
        isCorrect: false,
        value: "1 == '1'",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "1 == true",
      },
      {
        id: uuid4(),
        isCorrect: true,
        value: "true == 'true'",
      },
      {
        id: uuid4(),
        isCorrect: false,
        value: "true == 1",
      },
    ],
    company: Company.DEVDUCK,
    quizzId: "c77ef2c9-50c4-4b1f-9edb-869cb7794643",
    title: "Was ergibt false?",
  },
];
