import "@fontsource/muli";
import "@fontsource/muli/latin-700-normal.css";
import "@fontsource/muli/latin-700.css";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import QuizzPage from "./pages/QuizzPage";

const App: React.FC<{}> = () => {
  return (
    <Router>
      <Routes>
        {/* This is the drone contest starter page */}
        <Route index element={<QuizzPage />} />
        {/* This is the workshop contest starter page */}
        {/* <Route index element={<WorkshopPrice />} /> */}
        <Route path="/itcs-landing" element={<LandingPage />} />
        <Route path="/quizz/:uid" element={<QuizzPage />} />
      </Routes>
    </Router>
  );
};

export default App;
