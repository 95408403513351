import { FormEvent, useMemo, useRef, useState } from "react";
import DevDuckLogo from "../assets/devduck_logo.png";
import DuckeneersLogo from "../assets/duckeneers_logo.png";
import { generateQuizzResult } from "../utils/quizz/Quizz.firebase";
import { Answer, Company, Question } from "../utils/quizz/Quizz.types";
import arrayShuffle from "array-shuffle";

const AnswerButton: React.FC<{
  answer: Answer;
  onClick(correct: boolean): void;
  company: Company;
}> = ({ answer, onClick, company }) => {
  return (
    <div
      className={[
        "answer-button__wrapper",
        company === Company.DUCKENEERS ? "duckeneers" : "devduck",
      ].join(" ")}
      onClick={() => onClick(answer.isCorrect)}
    >
      {answer.value}
    </div>
  );
};

const EndScreen: React.FC<{
  correctAnswerCount: number;
  company: Company;
}> = ({ company, correctAnswerCount }) => {
  const companyCss: string = useMemo(
    () => (company === Company.DUCKENEERS ? "duckeneers" : "devduck"),
    [company]
  );
  const [wantsToWin, toggleWantsToWin] = useState<boolean>(false);
  const [mail, setMail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [privacyAccepted, togglePrivacy] = useState<boolean>(false);
  const submitButton = useRef<HTMLButtonElement>(null);

  const handleSubmit = (evt?: FormEvent<HTMLFormElement>): void => {
    evt?.preventDefault();
    if (!privacyAccepted) return;
    generateQuizzResult(mail, name, correctAnswerCount, company).then(() =>
      toggleWantsToWin(true)
    );
  };

  return wantsToWin ? (
    <div className={["win-screen__wrapper"].join(" ")}>
      <p className="win-screen__title">
        Vielen Dank für deine Teilnahme an unserem Gewinnspiel, du befindest
        dich jetzt im Lostopf. Wir werden uns in Kürze bei dir melden, falls du
        gewonnen hast.
      </p>
      <p className="win-screen__content">
        Behalte deine E-Mails im Auge und achte auch auf deinen Spam-Ordner. In
        der Zwischenzeit kannst du dir gerne mehr Informationen über uns
        anschauen:
      </p>
      <div
        className={["end-screen__submit", companyCss].join(" ")}
        onClick={() =>
          window.open(`${"https://www.devduck.de/karriere/"}`, "_blank")
        }
      >
        Zu den Jobs 🚀
      </div>
      <div
        className={["end-screen__submit", companyCss].join(" ")}
        onClick={() =>
          window.open(`${"https://www.devduck.de/unternehmen/"}`, "_blank")
        }
      >
        Mehr über uns
      </div>
      <div
        className={["end-screen__submit", companyCss].join(" ")}
        onClick={() =>
          window.open(`${"https://www.devduck.de/leistungen/"}`, "_blank")
        }
      >
        Unsere Leistungen
      </div>
    </div>
  ) : (
    <div className="end-screen__wrapper">
      <div className={["end-screen__done", companyCss].join(" ")}>
        Fertig 🎉
        <br />
        {correctAnswerCount} von 10 waren richtig!
      </div>
      <div className="end-screen__title">Du willst unsere Drohne gewinnen?</div>
      <div className="end-screen__content">
        Dann lass deine E-Mail da, wir kontaktieren dich, falls du sie gewonnen
        hast!
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className={["end-screen__mail", companyCss].join(" ")}
          required
          placeholder="Dein Name"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
        <input
          type="email"
          className={["end-screen__mail", companyCss].join(" ")}
          required
          placeholder="Deine Mail Adresse"
          value={mail}
          onChange={(evt) => setMail(evt.target.value)}
        />
        <button type="submit" hidden />
        <label className="checkbox-wrapper welcome-party__checkbox">
          <input
            type="checkbox"
            required
            onChange={() => togglePrivacy((old) => !old)}
          />
          <svg
            className={`checkbox ${privacyAccepted ? "checkbox--active" : ""}`}
            aria-hidden="true"
            viewBox="0 0 15 11"
            fill="none"
          >
            <path
              d="M1 4.5L5 9L14 1"
              strokeWidth="2"
              stroke={privacyAccepted ? "#F2B00A" : "none"}
            />
          </svg>
          <span className="checkbox-label">
            Beim Gewinnspiel mitmachen und zukünftig über Jobangebote von
            DevDuck informiert werden!
          </span>
        </label>
        <div
          className={[
            "end-screen__submit",
            companyCss,
            privacyAccepted ? "" : "disabled",
          ].join(" ")}
          onClick={() => submitButton?.current?.click()}
        >
          Teilnehmen!
        </div>
        <button type="submit" hidden ref={submitButton} />
      </form>
    </div>
  );
};

const QuizzContent: React.FC<{
  selectedCompany: Company;
  questions: Question[];
}> = ({ selectedCompany, questions }) => {
  const localQuestions: Question[] = useMemo(
    () => questions.filter((question) => question.company === selectedCompany),
    [selectedCompany, questions]
  );
  const [answeredQuestions, setAnsweredQuestions] = useState<number>(0);
  const [correctAnsweredQuestions, setCorrectAnsweredQuestions] =
    useState<number>(0);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const handleAnswer = (correctAnswer: boolean): void => {
    correctAnswer && setCorrectAnsweredQuestions((old) => ++old);
    setAnsweredQuestions((old) => ++old);
  };

  return (
    <div className="quizz-content__wrapper">
      <div className="quizz-content__question__wrapper">
        <div
          className="quizz-content__question__progress"
          ref={progressBarRef}
          style={{ width: `${(localQuestions.length + 4) * 0.5}rem` }}
        >
          <div
            className={[
              "quizz-content__question__progress__bar",
              selectedCompany === Company.DEVDUCK ? "devduck" : "duckeneers",
            ].join(" ")}
            style={{
              width:
                answeredQuestions > 0 && progressBarRef.current
                  ? `calc(${
                      ((answeredQuestions - 1) / localQuestions.length) *
                      progressBarRef.current.clientWidth
                    }px + 1rem)`
                  : 0,
            }}
          />
          {localQuestions.map(() => (
            <div className="quizz-content__question__progress__dot" />
          ))}
        </div>
        {answeredQuestions < localQuestions.length ? (
          <>
            <div className="quizz-content__question__title">
              <p>{localQuestions[answeredQuestions].title}</p>
            </div>
            <div className="quizz-content__question__answer__wrapper">
              {arrayShuffle(localQuestions[answeredQuestions].answers).map(
                (answer) => (
                  <AnswerButton
                    answer={answer}
                    company={selectedCompany}
                    onClick={handleAnswer}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <EndScreen
            company={selectedCompany}
            correctAnswerCount={correctAnsweredQuestions}
          />
        )}
      </div>
    </div>
  );
};

export default QuizzContent;
