import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  Company,
  DevDuckQuizzEntries,
  DuckeneersQuizzEntries,
  Question,
} from "./Quizz.types";

export const loadAllQuestionsForQuizz = async (
  quizzId: string
): Promise<Question[]> => {
  const quizzQuery = query(
    collection(db, "questions"),
    where("quizzId", "==", quizzId)
  );
  return getDocs(quizzQuery).then((docs) =>
    docs.docs.map((doc) => doc.data() as Question)
  );
};

export const generateQuizzEntries = () => {
  DevDuckQuizzEntries.forEach((question) =>
    setDoc(doc(db, "questions", question.id), question)
  );
  DuckeneersQuizzEntries.forEach((question) =>
    setDoc(doc(db, "questions", question.id), question)
  );
};

export const generateQuizzResult = async (
  mail: string,
  name: string,
  points: number,
  company: Company
): Promise<boolean> => {
  return setDoc(doc(db, "results_stuttgart_2023", mail), {
    mail: mail,
    name: name,
    points: points,
    company: company,
  })
    .then(() => true)
    .catch(() => false);
};

export const generateInvitationEntry = async (
  mail: string,
  name: string,
  company: string
): Promise<boolean> => {
  return setDoc(doc(db, "welcome_party_guests", mail), {
    mail,
    name,
    company,
  })
    .then(() => true)
    .catch(() => false);
};

export const generateWorkshopEntry = async (
  mail: string,
  name: string,
  company: string
): Promise<boolean> => {
  return setDoc(doc(db, "workshop_contest_motek", mail), {
    mail,
    name,
    company,
    createDate: Timestamp.now(),
  })
    .then(() => true)
    .catch(() => false);
};
