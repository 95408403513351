import { Company } from "../utils/quizz/Quizz.types";

const CompanySelector: React.FC<{ onSelect(company: Company): void }> = ({
  onSelect,
}) => {
  return (
    <div className="company-selector__wrapper">
      <h2>Herzlich willkommen!</h2>
      <div className="company-selector__company-wrapper">
        Wir freuen uns über dein Interesse an unserem Gewinnspiel. Du hast hier
        die Chance eine einzigartige <b>DevDuck-Drohne</b> zu gewinnen. Alles
        was du dafür tun musst, ist an einem der beiden folgenden Quizze
        teilzunehmen und schon gelangst du in den Lostopf! Viel Glück!
        <p>
          Du fühlst dich fit im Bereich der <b>Webentwicklung</b> und möchtest
          dein Wissen unter Beweis stellen? Dann ist dieses Quiz genau das
          Richtige für dich:
        </p>
        <div
          className="company-selector__company-button devduck"
          onClick={() => onSelect(Company.DEVDUCK)}
        >
          <code className="language-typescript">
            {"onClick(() => startQuizz(TYPE.WEB))"}
          </code>
        </div>
      </div>
      <div className="company-selector__company-wrapper">
        <p>
          Oder liegen dir die Themen <b>Machine Learning</b> und <b>Robotik</b>{" "}
          doch mehr? Dann zeig dein Können in diesem Quiz:
        </p>
        <div
          className="company-selector__company-button duckeneers"
          onClick={() => onSelect(Company.DUCKENEERS)}
        >
          <code>{"onClick(() => startQuizz(TYPE.ML))"}</code>
        </div>
      </div>
    </div>
  );
};

export default CompanySelector;
